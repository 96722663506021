<script setup lang="ts">
import md5 from 'blueimp-md5'

const props = withDefaults(defineProps<{
  alt?: string
  email?: string
  hash?: string
  size?: number
  rounded?: boolean
  defaultImg?: string
  rating?: string
}>(), {
  alt: 'Avatar',
  size: 30,
  rounded: true,
  defaultImg: 'robohash',
  rating: 'x',
})

const url = computed(() => {
  if (props.email) {
    const emailHash = props.hash ?? md5(props.email.trim().toLowerCase())
    const queryParams = [
      `d=${props.defaultImg}`,
      props.size > 0 ? `s=${props.size}` : undefined,
      props.rating ? `r=${props.rating}` : undefined,
    ].filter(Boolean).join('&')
    return `https://gravatar.com/avatar/${emailHash}?${queryParams}`
  }
  return '/assets/headshots/default.webp'
})
</script>

<template>
  <img
    class="size-6 md:size-8"
    :class="rounded ? 'rounded-full' : ''"
    :src="url"
    :alt="alt"
  >
</template>
