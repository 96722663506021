<script setup lang="ts">
import { z } from 'zod'
import type { FormSubmitEvent } from '#ui/types'
import type SupaDatabase from '~/@types/SupabaseDB.d'

// const supabaseClient = useSupabaseClient()
// const user = useSupabaseUser()
const AuthStore = useAuthStore()
const { username, sleeperId, modalState, email, userId, getAuthString, level } = storeToRefs(AuthStore)
const form = ref()
const loading = ref(false)
const defaultValues = computed(() => ({
  username: username?.value,
  sleeperId: sleeperId?.value,
  password: undefined,
  confirmPassword: undefined,
}))

const schema = z.object({
  username: z.string().min(1).max(100).optional(),
  sleeperId: z.string().min(1).max(40).optional(),
  password: z.string().min(4).optional(),
  confirmPassword: z.string().min(4).optional(),
}).superRefine(({ confirmPassword, password }, ctx) => {
  if (confirmPassword !== password) {
    ctx.addIssue({
      code: 'custom',
      message: 'The passwords did not match',
      path: ['confirmPassword'],
    })
  }
})

const state = reactive<z.output<typeof schema>>({ ...defaultValues.value })
const hasChanges = computed(() => !_isEqual(state, defaultValues.value))

async function onSubmit(event: FormSubmitEvent<z.output<typeof schema>>) {
  try {
    loading.value = true
    if (!userId?.value)
      throw new Error('User ID not found')
    const supabase = useSupabaseClient<SupaDatabase.SupaDatabase>()
    const updates = { id: userId.value, username: event.data.username, sleeperId: event.data.sleeperId }
    const { error } = await supabase.from('profiles').upsert(updates)
    if (error)
      throw error
    if (event.data.password) {
      const { error } = await supabase.auth.updateUser({ password: event.data.password })
      if (error)
        throw error
    }
    AuthStore.$patch({ username: event.data.username, sleeperId: event.data.sleeperId })
    modalState.value = false
  }
  catch (error: any) {
    useToast().add({ title: 'Errors Updating profile', description: error.message })
  }
  finally {
    loading.value = false
  }
}

function onCancel() {
  if (hasChanges.value)
    Object.assign(state, { ...defaultValues.value })
  modalState.value = false
}
</script>

<template>
  <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
    <template #header>
      <div class="text-center">
        <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
          User Profile
        </h3>
      </div>
    </template>
    <UForm ref="form" :state="state" :schema="schema" class="mt-auto grid grid-cols-2 gap-4" @submit="onSubmit">
      <UFormGroup label="Avatar" name="avatar">
        <div class="flex flex-row">
          <Gravatar :email="email" />
          <UButton size="xs" variant="link" to="https://gravatar.com/">
            Change
          </UButton>
        </div>
      </UFormGroup>
      <UFormGroup label="Current Subscription" name="subscription">
        <UBadge variant="outline" color="white" size="md" :ui="{ rounded: 'rounded-full' }">
          {{ _startCase(getAuthString) }} Tier
        </UBadge>
        <UButton v-if="level < 5" size="xs" variant="link" to="/subscription">
          Upgrade!
        </UButton>
      </UFormGroup>
      <UFormGroup label="Username" name="username">
        <UInput v-model="state.username" />
      </UFormGroup>
      <UFormGroup label="Sleeper ID" name="sleeperId">
        <UInput v-model="state.sleeperId" />
      </UFormGroup>
      <UFormGroup label="New Password" name="password">
        <UInput v-model="state.password" type="password" />
      </UFormGroup>
      <UFormGroup v-show="(state.password?.length || 0) > 0" label="Confirm Password" name="confirmPassword">
        <UInput v-model="state.confirmPassword" type="password" />
      </UFormGroup>
      <UDivider class="col-span-2 mt-4" />
      <div class="col-span-2 flex justify-end gap-2">
        <UButtonGroup size="sm" orientation="horizontal">
          <UButton :ui="{ rounded: 'rounded-full' }" label="Save" variant="outline" type="submit" :loading="loading" color="green" icon="i-material-symbols-save-outline" />
          <UButton :ui="{ rounded: 'rounded-full' }" label="Cancel" variant="outline" color="red" icon="i-material-symbols-cancel-outline" @click="onCancel" />
        </UButtonGroup>
      </div>
    </UForm>
  </UCard>
</template>
